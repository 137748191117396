import { map, replace, trim, isNull, isEqual } from 'lodash'
import moment from 'moment-timezone';
import { set } from 'layouts/actions'
import { appIdSelector, cpfCnpjSelector } from 'modules/Login/selectors/user'
import { open, boleto, installmentsInfoRequest } from 'modules/Finance/services/installment'
import { paymentRede, paymentDebit, processdebitpayment, checkDebit } from 'modules/Finance/services/financial'
import { ERROR_UNEXPECTED } from 'utils/message'
import {
  REQUEST_OPENED,
  SUCCESS_OPENED,
  REFRESH_OPENED,
  ERROR_OPENED,
  REQUEST_BILLET,
  SUCCESS_BILLET,
  RESET_OPENED,
  SUCCESS_CONFIRM_PAYMENT,
  WAITING_REDE_PAYMENT,
  RESET_PAYMENT,
  MESSAGE_ERROR_PAYMENT_DEBIT,
  MESSAGE_ERROR_PAYMENT_CREDIT,
  PAYMENT_DEBIT,
  PAYMENT_CREDIT,
  PROCESSING_DEBIT,
} from '../constants'
import { apiRequestedAction, apiErrorAction, apiSuccessAction } from 'utils/API/actions/status'
import { apiAnalyticsInfo } from 'utils/GTM/action'
import qs from 'qs'

// Opened
export const requestPaymentOpen = () => ({
  type: REQUEST_OPENED,
})

export const successPaymentOpen = (payment) => ({
  type: SUCCESS_OPENED,
  data: payment
})

export const refreshPaymentOpen = (payment) => ({
  type: REFRESH_OPENED,
  data: payment
})

export const errorPaymentOpen = (message) => ({
  type: ERROR_OPENED,
  data: message
})

export const resetPaymentOpen = () => ({
  type: RESET_OPENED,
})

export const resetPayment = () => ({
  type: RESET_PAYMENT,
})

export const waitingRedePayment = (status) => ({
  type: WAITING_REDE_PAYMENT,
  data: status
})

export const successConfirmPayment = (receipt) => ({
  type: SUCCESS_CONFIRM_PAYMENT,
  data: receipt
})

export const requestPaymentBillet = () => ({
  type: REQUEST_BILLET,
})

export const successBillet = (payload) => ({
  type: SUCCESS_BILLET,
  data: payload,
})

export const fetchOpened = (params) => (dispatch, getState) => {
  const appid = appIdSelector(getState())
  return dispatch(open(appid, params))
}

export const fetchInstallmentsInfo = (params) => (dispatch, getState) => {
  return dispatch(installmentsInfoRequest(params))
}

export const downloadBillet = param => (dispatch, getState) => {
  dispatch(requestPaymentBillet())

  const cpfCnpj = cpfCnpjSelector(getState())

  return boleto(param, cpfCnpj).then(result => {
    if (!result.fileurl) {
      throw new Error()
    }
    dispatch(successBillet(result.fileurl))
    return result.fileurl
  }).catch(erro => {
    dispatch(set(ERROR_UNEXPECTED))
  })
}

export const successRedeDefault = { success: true, message: 'Baixa em processamento. Aguarde alguns instantes!', receipt: {} }

const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms))

export const sendPaymentDebit = (items, infoPayment) => (dispatch, getState) => {
  const appId = appIdSelector(getState())
  const [item,] = items
  const { proposalId, dataareaid, accountresponsible } = item
  const { cardNumber, cvv, expiryDateMonth, expiryDateYear, cardName, totalDiscount, isAgreement, cpf } = infoPayment
  const parcelas = map(items, item => item.referenceid)
  const totalToPay = parseFloat(items.reduce((prev, curr) => prev + Number(curr.correctvalue), 0)).toFixed(2)
  const cpfCnpj = cpfCnpjSelector(getState())

  const body = {
    parcelas,
    cardNumber: trim(replace(cardNumber, / /g, '')),
    securityCode: trim(cvv),
    validityMonth: expiryDateMonth,
    validityYear: expiryDateYear,
    cardHolder: cardName,
    totalToPay,
    totalDiscount: parseFloat(totalDiscount).toFixed(2),
    quantity: 1,
    datatransacao: moment().tz("America/Sao_Paulo").format('YYYY-MM-DDTHH:mm:ss'),
    proposalId: proposalId,
    isAgreement: isAgreement,
    dataAreaId: dataareaid,
    customerAccount: accountresponsible,
    cpf: cpf,
    appId
  }

  dispatch(paymentDebit(body, cpfCnpj)).then(result => {
    if (result.success) {
      dispatch(apiRequestedAction(PAYMENT_DEBIT))
      processdebitpayment(result.url, qs.stringify({
        PaReq: result.payerAuthenticationRequest,
        MD: result.issuerPaymentId,
        TermUrl: result.termUrl
      })).then(request => {
        const button = document.getElementById('rede')
        button.addEventListener('click', (event) => {
          dispatch(apiAnalyticsInfo(PAYMENT_DEBIT, 'débito'))
          openWindow(result, dispatch, appId, request)
        }, false)
      }).catch(() => {
        dispatch(apiErrorAction(PAYMENT_DEBIT, { message: MESSAGE_ERROR_PAYMENT_DEBIT }))
      })
    } else {
      dispatch(apiErrorAction(PAYMENT_DEBIT, { message: MESSAGE_ERROR_PAYMENT_DEBIT }))
    }
  }).catch(error => {
    handleError(error, dispatch, MESSAGE_ERROR_PAYMENT_DEBIT, PAYMENT_DEBIT)
  })
}

const openWindow = async (result, dispatch, appId, html) => {
  try {
    dispatch(waitingRedePayment(true))
    const windowRef = window.open('', '_blank', 'width=930, height=500, top=0, left=0')
    let waiting = true
    let send = true
    if (windowRef) {
      windowRef.document.write(html)
      windowRef.addEventListener('beforeunload', (event) => {
        setTimeout(() => windowRef.close(), 500)
      })
      while (windowRef && !windowRef.closed) {
        await sleep(10000)
      }
      while (waiting) {
        if (send) {
          dispatch(checkDebit(appId, result.orderNumber))
            // eslint-disable-next-line
            .then(request => {
              const processing = isEqual(request.transaction, PROCESSING_DEBIT)
              waiting = processing
              send = processing
              // eslint-disable-next-line
            }).catch(error => {
            waiting = false
            send = false
            handleError(error, dispatch, MESSAGE_ERROR_PAYMENT_DEBIT, PAYMENT_DEBIT)
          })
          send = false
        }
        await sleep(2000)
      }
    }
  } catch (error) {
    handleError(error, dispatch, MESSAGE_ERROR_PAYMENT_DEBIT, PAYMENT_DEBIT)
  } finally {
    dispatch(waitingRedePayment(false))
  }
}

export const sendPaymentCredit = (items, infoPayment) => (dispatch, getState) => {
  const appId = appIdSelector(getState())
  const [item,] = items
  const { proposalId, dataareaid, accountresponsible } = item
  const { cardNumber, cvv, expiryDateMonth, expiryDateYear, cardName, totalDiscount, isAgreement, installment, installmments, cpf } = infoPayment
  const parcelas = installmments || map(items, item => item.referenceid)
  const totalToPay = parseFloat(items.reduce((prev, curr) => prev + Number(curr.correctvalue), 0)).toFixed(2)
  const cpfCnpj = cpfCnpjSelector(getState())

  const body = {
    parcelas,
    cardNumber: trim(replace(cardNumber, / /g, '')),
    securityCode: trim(cvv),
    validityMonth: expiryDateMonth,
    validityYear: expiryDateYear,
    cardHolder: cardName,
    totalToPay,
    totalDiscount: totalDiscount ? parseFloat(totalDiscount).toFixed(2) : 0,
    quantity: installment || 1,
    datatransacao: moment().tz("America/Sao_Paulo").format('YYYY-MM-DDTHH:mm:ss'),
    proposalId: proposalId,
    isAgreement: isAgreement,
    dataAreaId: dataareaid,
    customerAccount: accountresponsible,
    cpf: cpf,
    appId
  }

  dispatch(paymentRede(body, cpfCnpj)).then(result => {
    if (result.success === undefined) {
      dispatch(apiSuccessAction(PAYMENT_CREDIT, isNull(result.message) ?
        successRedeDefault :
        { success: true, message: result.message, receipt: {} }))
    } else if (!result.success) {
      dispatch(apiErrorAction(PAYMENT_CREDIT, { message: isNull(result.message) ? MESSAGE_ERROR_PAYMENT_CREDIT : result.message }))
    }
  }).catch(error => handleError(error, dispatch, MESSAGE_ERROR_PAYMENT_CREDIT, PAYMENT_CREDIT))

}

const handleError = (error, dispatch, message, entity) => {
  if (error && error.response && error.response.status === 400) {
    const { response: { data } } = error
    dispatch(apiErrorAction(entity, { message: isNull(data.message) ? message : data.message }))
  }
}
